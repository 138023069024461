<template>
  <b-container class="import bg-light page-wrapper">
    <div class="main-content-wrapper pb-0" no-gutters>
      <b-row no-gutters>
        <b-col cols="12">
          <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
        </b-col>
        <b-col cols="12">
          <h1 class="mt-1">{{ $t('transactions.submenu.transactions') }}</h1>
        </b-col>

        <b-col cols="10" class="sidebar-text" >
          <div class="mb-0" v-html="$t(translationPath + 'title_description')"></div>
        </b-col>
      </b-row>
    </div>

    <b-row class="main-content-wrapper mt-3 pt-0 mt-0">
      <b-col lg="4" md="6" sm="6" xs="12" class="mb-3 mb-lg-4">
        <b-card
          class="mb-2 reports_cards_main"
          :img-src="require('@/assets/img/import.png')"
          img-alt="Import account statement"
          @click="onCardClick('/transactions/import/account-statement/')"
        >

          <b-card-title class="text-left">
            {{ $t( translationPath + 'import_account_statement') }}
          </b-card-title>
          <b-card-text>
            {{ $t( translationPath + 'import_account_statement_description') }}
          </b-card-text>
          <div slot="footer">
            <b-button class="btn btn-sm btn-primary font-weight-bold d-block">
              {{ $t( translationPath + 'import_account_statement_open') }} <i class="flaticon stroke right-2"></i>
            </b-button>
          </div>
        </b-card>
      </b-col>

      <b-col lg="4" md="6" sm="6" xs="12" class="mb-3 mb-lg-4">
        <b-card
          class="mb-2 reports_cards_main"
          :img-src="require('@/assets/img/bankid-import.png')"
          img-alt="Sync with bank account"
          @click="onCardClick('/transactions/import/tink')"
        >
          <b-card-title class="text-left">
            {{ $t( translationPath + 'sync_bank_account') }}
          </b-card-title>
          <b-card-text>
            {{ $t( translationPath + 'sync_bank_account_description') }}
          </b-card-text>
          <div slot="footer">
            <b-button class="btn btn-sm btn-primary font-weight-bold d-none d-sm-block" variant="secondary">
              {{ $t( translationPath + 'sync_bank_account') }} <i class="flaticon stroke right-2"></i>
            </b-button>
          </div>
        </b-card>
      </b-col>

      <b-col lg="4" md="6" sm="6" xs="12" class="mb-3 mb-lg-4">
        <b-card
          class="mb-2 reports_cards_main"
          :img-src="require('@/assets/img/transactions-all.png')"
          @click="onCardClick('/transactions/view')"
        >
          <b-card-title class="text-left">
            {{ $t( translationPath + 'all_transactions_title') }}
          </b-card-title>
          <b-card-text>
            {{ $t( translationPath + 'all_transactions_description') }}
          </b-card-text>
          <div slot="footer">
            <b-button class="btn btn-sm font-weight-bold d-block">
              {{ $t( translationPath + 'all_transactions_open') }} <i class="flaticon stroke right-2"></i>
            </b-button>
          </div>
        </b-card>
      </b-col>

      <b-col lg="4" md="6" sm="6" xs="12" class="mb-3 mb-lg-4">
        <b-card
          class="mb-2 reports_cards_main"
          :img-src="require('@/assets/img/add-income.png')"
          img-alt="Add income or expense"
          @click="onCardClick('/transactions/create/single')"
        >

          <b-card-title class="text-left">
            {{ $t( translationPath + 'add_single_transaction') }}
          </b-card-title>
          <b-card-text>
            {{ $t( translationPath + 'add_single_transaction_description') }}
          </b-card-text>
          <div slot="footer">
            <b-button class="btn btn-sm font-weight-bold d-block">
              {{ $t( translationPath + 'add_single_transaction_open') }} <i class="flaticon stroke right-2"></i>
            </b-button>
          </div>
        </b-card>
      </b-col>

      <b-col lg="4" md="6" sm="6" xs="12" class="mb-3 mb-lg-4">
        <b-card
          class="mb-2 reports_cards_main"
          :img-src="require('@/assets/img/add-multiple-transactions.png')"
          img-alt="Add multiple incomes or expenses"
          @click="onCardClick('/transactions/create/multiple')"
        >
          <b-card-title class="text-left">
            {{ $t( translationPath + 'add_multiple_transactions') }}
          </b-card-title>
          <b-card-text>
            {{ $t( translationPath + 'add_multiple_transactions_description') }}
          </b-card-text>
          <div slot="footer">
            <b-button class="btn btn-sm btn-primary font-weight-bold d-block">
              {{ $t( translationPath + 'add_multiple_transactions_open') }} <i class="flaticon stroke right-2"></i>
            </b-button>
          </div>
        </b-card>
      </b-col>

      <b-col lg="4" md="6" sm="6" xs="12" class="mb-3 mb-lg-4">
        <b-card
          class="mb-2 reports_cards_main"
          :img-src="require('@/assets/img/upload-excel.png')"
          img-alt="Upload excel-file"
          @click="onCardClick('/transactions/import/excel')"
        >
          <b-card-title class="text-left">
            {{ $t( translationPath + 'upload_excel') }}
          </b-card-title>
          <b-card-text>
            {{ $t( translationPath + 'upload_excel_description') }}
          </b-card-text>
          <div slot="footer">
            <b-button class="btn btn-sm font-weight-bold d-block">
              {{ $t( translationPath + 'upload_excel') }} <i class="flaticon stroke right-2"></i>
            </b-button>
          </div>
        </b-card>
      </b-col>

      <b-col lg="4" md="6" sm="6" xs="12" class="mb-3 mb-lg-4">
        <b-card
          class="mb-2 reports_cards_main"
          :img-src="require('@/assets/img/advanced-excel-import.png')"
          img-alt="Upload excel-file"
          @click="onCardClick('/transactions/import/excel-advanced')"
        >
          <b-card-title class="text-left">
            {{ $t( translationPath + 'upload_excel_advanced') }}
          </b-card-title>
          <b-card-text>
            {{ $t( translationPath + 'upload_excel_advanced_description') }}
          </b-card-text>
          <div slot="footer">
            <b-button class="btn btn-sm font-weight-bold d-block">
              {{ $t( translationPath + 'upload_excel_advanced') }} <i class="flaticon stroke right-2"></i>
            </b-button>
          </div>
        </b-card>
      </b-col>

      <b-col lg="4" md="6" sm="6" xs="12" class="mb-3 mb-lg-4">
        <b-card
          class="mb-2 reports_cards_main"
          :img-src="require('@/assets/img/balance-fix.png')"
          img-alt="Import rules"
          @click="onCardClick({name: 'balance-fix'})"
        >
          <b-card-title class="text-left">
            {{ $t( translationPath + 'balancefix') }}
          </b-card-title>
          <b-card-text>
            {{ $t( translationPath + 'balancefix_description') }}
          </b-card-text>
          <div slot="footer">
            <b-button class="btn btn-sm font-weight-bold d-none d-sm-block">
              {{ $t( translationPath + 'balancefix_open') }} <i class="flaticon stroke right-2"></i>
            </b-button>
          </div>
        </b-card>
      </b-col>

      <!-- Transaction tags -->
      <b-col lg="4" md="6" sm="6" xs="12" class="mb-3 mb-lg-4" v-show="inDevelopmentMode">
        <b-card
          class="mb-2 reports_cards_main"
          :img-src="require('@/assets/img/tags-admin.png')"
          img-alt="Import rules"
          @click="onCardClick({name: 'transaction-tags'})"
        >
          <b-card-title class="text-left">
            {{ $t( translationPath + 'transaction_tags_title') }}
          </b-card-title>
          <b-card-text>
            {{ $t( translationPath + 'transaction_tags_description') }}
          </b-card-text>
          <div slot="footer">
            <b-button class="btn btn-sm font-weight-bold d-none d-sm-block">
              {{ $t( translationPath + 'transaction_tags_open') }} <i class="flaticon stroke right-2"></i>
            </b-button>
          </div>
        </b-card>
      </b-col>

      <b-col lg="4" md="6" sm="6" xs="12" class="mb-3 mb-lg-4">
        <b-card
          class="mb-2 reports_cards_main"
          :img-src="require('@/assets/img/budget.png')"
          img-alt="Import rules"
          @click="onCardClick({name: 'ie-rules'})"
        >
          <b-card-title class="text-left">
            {{ $t( translationPath + 'import_rules') }}
          </b-card-title>
          <b-card-text>
            {{ $t( translationPath + 'import_rules_description') }}
          </b-card-text>
          <div slot="footer">
            <b-button class="btn btn-sm font-weight-bold d-none d-sm-block">
              {{ $t( translationPath + 'import_rules_open') }} <i class="flaticon stroke right-2"></i>
            </b-button>
          </div>
        </b-card>
      </b-col>

    </b-row>
  </b-container>
</template>

<script>
import TitleMixins from '@/mixins/title'

export default {
  name: 'TransactionImportCards',
  components: { },
  mixins: [TitleMixins],
  data () {
    return {
      translationPath: 'transactions.import._common.'
    }
  },
  computed: {
    breadcrumbItems () {
      return [
        { text: this.$t('common.header.header_menu.transactions.transactions'), to: '/transactions/import/all' },
        { text: '', to: '', active: true }
      ]
    },
    inDevelopmentMode () {
      return process.env.NODE_ENV === 'development'
    }
  },
  methods: {
    onCardClick (route) {
      this.$router.push(route)
    }
  },
  created () {
    const pageTitle = this.$t('transactions.submenu.import')
    this.setPageTitle(pageTitle)
  },
  updated () {
    const pageTitle = this.$t('transactions.submenu.import')
    this.setPageTitle(pageTitle)
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/reports-cards.scss';
  .import {
    .reports_cards_main {
      cursor: pointer;
    }
    .card-icon{
      font-size: 48px;
      font-weight: 200
    }
    .card-img {
      // min-height: 199px;
      @media screen and (max-width:576px) {
        min-height: auto;
      }
      @media screen and (min-width:577px) and (max-width:768px) {
        min-height: auto;
      }
      @media screen and (min-width:769px) and (max-width: 991px) {
        // min-height: 193px;
      }
      @media screen and (min-width:992px) and (max-width: 1400px) {
        // min-height: 193px;
      }
    }
    .card-header {
      min-height: 199px;
      @media screen and (min-width:769px) and (max-width: 991px) {
        min-height: 100px;
      }
      @media screen and (min-width:992px) and (max-width: 1400px) {
        min-height: 100px;
      }
    }
    .card-img{
      background: transparent;
      border-bottom: none;
    }
    .btn {
      border-radius: 20px;
      padding: 5px 12px;
      margin-right:10px;
      font-size:80%;
      width: 100%;
    }
    .btn i{vertical-align: inherit;}
    .card-body{
      min-height: 144px;
      @media screen and (min-width:577px) and (max-width:768px) {
        min-height: 188px;
      }
      @media screen and (min-width:769px) and (max-width: 991px) {
        min-height: 144px;
      }
      @media screen and (min-width:992px) and (max-width: 1449px) {
        min-height: 190px;
      }
    }
  }
</style>
